<template>
  <div class="publication3DItem">
    <router-link :to="{ name: 'Publication3DDetail', query: { id: item.id } }">
      <div class="clickable">
        <div
          :alt="`${item.title}`"
          v-if="!item.url.includes('sketchfab')"
          class="publication3DItem__img"
          :style="`background-image: url(${item.url})`"
        ></div>
        <div v-else class="publication3DItem__img">
          <iframe
            :title="item.titleZh"
            frameborder="0"
            allowfullscreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            :src="item.url"
            class="publication3DItem__iframe"
          >
          </iframe>
        </div>

        <h3 class="publication3DItem__title">{{ item.titleZh }}</h3>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        authorEn: '',
        authorZh: 'Hiawatha Seiffert',
        id: '3',
        image: [],
        introEn:
          'Objet trouve. Treasure of culture sites of industrial elements. Locality unknown. Finding in the right place at the right time. A technical product, bicycle-& machine chains, forged into layers, ingeniously bonded by sheer physical force, in context as a transformed expression of organic power – in spite of weight of material, the objects take on a new aesthetics of lightness with their gaps. Because of heating and well-targeted action of force my bowls get a special into space reaching dynamic. It`s very i',
        introZh:
          '曾幾何時，這些零散、不起眼的工業廢料，也能蛻變成絢麗的文化寶藏？答案是將它們在對的時間，放在對的地方。 本作品那腳踏車與機器的鏈條層層疊疊，象徵著堅韌的連結，呈現物質的靜態和有機的動力，亦透過鏈條的間隙打破鋼鐵沈重的刻板印象，賜予它全新的輕巧美學。 我以適當的熱度和明確的壓力，讓這個碗在空間中展現力學的獨特面向。對我而言，素材的特性造就這件作品所要表達的含意。',
        materialEn: '',
        materialZh: '腳踏車與機器鍊條 Bicyle- & Machine Chain',
        size: '28x28x14 (長/寬/高 單位:公分)',
        titleEn: '',
        titleZh: 'BC28-14-V2A',
        url:
          'https://www.gep.ntpc.gov.tw/files/file_pool/1/0I130397424895754752/01.gif'
      })
    }
  }
}
</script>

<style lang="scss">
.publication3DItem {
  width: 33.33%;
  padding: 3.5rem;

  &__img {
    padding-bottom: 55.055%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &__title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    text-align: center;
    margin-top: 1.25rem;
  }
}

@media (max-width: 768px) {
  .publication3DItem {
    width: 100%;
    padding: 0;
    padding-bottom: 3.5rem;
  }
}
</style>
