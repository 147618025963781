<template>
  <div class="publicationQuerySearch">
    <BaseLabel
      class="publicationQuerySearch__label"
      :labelFor="'publicationQuerySearch__input'"
      :text="'一般檢索'"
    />

    <div class="publicationQuerySearch__inputContainer">
      <input
        autocomplete="off"
        :value="keyword"
        @input="onChange"
        class="publicationQuerySearch__input"
        id="publicationQuerySearch__input"
        type="text"
        placeholder="直接輸入關鍵字或展品編號"
      />
      <button
        @click="$emit('submit')"
        class="publicationQuerySearch__searchButton clickable"
        title="點擊後依輸入關鍵字進行搜尋"
      ></button>
    </div>
  </div>
</template>

<script>
import BaseLabel from '@/components/BaseLabel.vue'

export default {
  components: { BaseLabel },
  props: {
    keyword: {
      type: String
    }
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss">
.publicationQuerySearch {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__label {
    margin-right: 2.6rem;
  }

  &__inputContainer {
    width: 34.4rem;
    position: relative;
  }

  /* url('~@/assets/image/common/InputArrow.jpg') */
  &__input {
    padding: 1.3rem 6rem 1.3rem 4rem;
    background-image: url('~@/assets/image/common/Search.png');
    background-repeat: no-repeat;
    background-position: 1.6rem;
    background-size: 20px 20px;
    border: 1px solid #d0a805;
    width: calc(100% - 5rem);
    height: 5rem;
  }

  &__searchButton {
    width: 5rem;
    height: 5rem;
    background-image: url('~@/assets/image/common/InputArrow.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 5rem 5rem;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

@media (max-width: 768px) {
  .publicationQuerySearch {
    &__inputContainer {
      width: 100%;
    }

    &__label {
      margin-bottom: 1.2rem;
    }
  }
}
</style>
