<template>
  <label class="baseLabel" :for="labelFor">{{ text }}</label>
</template>
<script>
export default {
  props: {
    labelFor: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.baseLabel {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
}

.baseLabel::before {
  content: '';
  width: 1.3rem;
  height: 1.3rem;
  background: #d0a805;
  display: inline-block;
  margin-right: 1rem;
}
</style>
