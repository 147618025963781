<template>
  <section class="publication3D container">
    <form @submit.prevent="onSearch">
      <PublicationQuery v-model="keyword" />
    </form>

    <hr class="publication3D__hr" />

    <div class="publication3D__itemContainer">
      <Item3D
        v-for="relics in relicsListFilter"
        :key="relics.id"
        :item="relics"
      />
    </div>

<!--    <BasePagination-->
<!--      :total="relicsList.length"-->
<!--      :page-size="pageSize"-->
<!--      :current-page="currentPage"-->
<!--      @current-change="onCurrentPageChange"-->
<!--    />-->

<!--    <paginate-->
<!--      v-model="currentPage"-->
<!--      :page-count="relicsList.page"-->
<!--      :page-range="3"-->
<!--      :margin-pages="2"-->
<!--      :prev-text="'Prev'"-->
<!--      :next-text="'Next'"-->
<!--      :container-class="'pagination'"-->
<!--      :page-class="'page-item'">-->
<!--    </paginate>-->

    <b-pagination-nav
      v-model="currentPage"
      :number-of-pages="Math.ceil(this.relicsList.length/18).toString()"
      base-url="?page="
      use-router
      align="center"
      size="lg"
      first-number
      last-number
    ></b-pagination-nav>

<!--    <b-pagination-nav-->
<!--      v-model="currentPage"-->
<!--      :number-of-pages="pageSize"-->
<!--      base-url="?page="-->
<!--      use-router-->
<!--    ></b-pagination-nav>-->

    <hr class="publication3D__hr" />
  </section>
</template>
<script src="//unpkg.com/vue-plain-pagination@0.2.1"></script>
<script>
import PublicationQuery from '@/components/Publication/PublicationQuerySearch.vue'
import Item3D from '@/components/Publication/3D/3DItem.vue'
import BasePagination from '@/components/BasePagination.vue'

// Api
import { apiGetRelics } from '@/api/webAPI'
import $ from "jquery";

export default {
  components: {
    PublicationQuery,
    Item3D,
    BasePagination
  },
  data() {
    return {
      keyword: '',
      relicsList: [],
      currentPage: 1,
      pageSize: 18,
    }
  },
  computed: {
    relicsListFilter() {
      const pageSize = this.pageSize
      const startIndex = pageSize * (this.currentPage - 1)
      const endIndex = pageSize * this.currentPage

      return this.relicsList.slice(startIndex, endIndex)
    }
  },
  watch: {
    $route(to, from) {
      this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      const { page, keyword } = this.$route.query
      if (keyword) this.keyword = keyword
      this.currentPage = +page || 1

      const relicsResponse = (
        await apiGetRelics({
          keyword: this.keyword
        })
      ).data.data

      const result = []
      for (const relics of relicsResponse) {
        result.push(...relics.relics)
      }

      this.relicsList = result
    },

    onCurrentPageChange(page) {
      const query = this.$route.query
      this.$router.push({
        name: 'Publication3D',
        query: {
          ...query,
          page: page
        }
      })
    },
    onSearch() {
      const query = this.$route.query

      this.$router.push({
        name: 'Publication3D',
        query: {
          ...query,
          page: 1,
          keyword: this.keyword
        }
      })
    }
  },
  created() {
    this.fetchData()
  },
  mounted(){
    $('ul.pagination >:first-child').attr('title', '上一頁')
    $('ul.pagination >:last-child').attr('title', '下一頁')
    // $("button").removeAttr('tabindex');
  }
}
</script>
<style lang="scss">
.publication3D {
  padding: 4.8rem;

  &__itemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__hr {
    border: none;
    border-bottom: 1px dashed #d0a805;
    width: 100%;
    margin: 10rem 0 1.2rem 0;
  }
}

@media (max-width: 768px) {
  .publication3D {
    &__hr {
      margin: 2rem auto;
    }
  }
}
</style>
